import { AppBar, Toolbar } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import MenuIcon from "../../assets/icons/hamburger.svg";
import TimeMenu from "./time-menu";
import ConnectButton from "./connect-button";
import WrapButton from "./wrap-button";
import "./header.scss";
import { DRAWER_WIDTH, TRANSITION_DURATION } from "../../constants/style";
import { useWeb3Context } from "../../hooks";
import { Networks } from "../../constants/blockchain";
import NetworkMenu from "./network-menu";
import documents from "../../assets/images/documents.png";
import faq from "../../assets/images/faq.png";
import support from "../../assets/images/support.png";
import contract from "../../assets/images/contract-shiled.png";
import verified from "../../assets/images/verified.png";
interface IHeader {
    handleDrawerToggle: () => void;
    drawe: boolean;
}

const useStyles = makeStyles(theme => ({
    appBar: {
        [theme.breakpoints.up("sm")]: {
            width: "100%",
        },
        justifyContent: "flex-end",
        alignItems: "flex-end",
        background: "transparent",
        backdropFilter: "none",
        zIndex: 10,
    },
    topBar: {
        transition: theme.transitions.create("margin", {
            easing: theme.transitions.easing.sharp,
            duration: TRANSITION_DURATION,
        }),
        marginLeft: DRAWER_WIDTH,
    },
    topBarShift: {
        transition: theme.transitions.create("margin", {
            easing: theme.transitions.easing.easeOut,
            duration: TRANSITION_DURATION,
        }),
        marginLeft: 0,
    },
}));

function Header({ handleDrawerToggle, drawe }: IHeader) {
    const classes = useStyles();
    const isVerySmallScreen = useMediaQuery("(max-width: 548px)");
    const isWrapShow = useMediaQuery("(max-width: 710px)");
    const { chainID } = useWeb3Context();

    return (
        <div className={`${classes.topBar} ${!drawe && classes.topBarShift}`}>
            {/* <div className="admin-title">
                <a href="#" target="_blank" className="btn btn-primary">
                    <img src={documents} /> Documentation
                </a>
                <a href="#" target="_blank" className="btn btn-primary">
                    <img src={faq} /> FAQs
                </a>
                <a href="#" target="_blank" className="btn btn-primary">
                    <img src={support} />
                    Support
                </a>
                <div className="verified-contract">
                    <img src={contract} />
                    <p>
                        <span>Verified Contract:</span> 0x0000000000000000000000000000000
                    </p>
                    <a href="#" target="_blank" className="btn btn-secondary small">
                        View on Ethscan
                    </a>
                </div>
                <a href="https://testnet.bscscan.com/address/0xfCd06B7F1C4393392bd8b299bE72b28C3e446295#code" className="btn btn-green" target="_blank">
                    <img src={verified} /> Verified &amp; Audited Contract
                </a>
                <ConnectButton />
                <a href="#" className="btn btn-green">
                    <img src="images/verified.png" /> Verified & Audited Contract
                </a>
            </div> */}
            <AppBar position="sticky" className={classes.appBar} elevation={0}>
                <Toolbar disableGutters className="dapp-topbar">
                    <div onClick={handleDrawerToggle} className="dapp-topbar-slider-btn">
                        <img src={MenuIcon} alt="" />
                    </div>
                    {/* <div className="dapp-topbar-btns-wrap">
                        {!isWrapShow && chainID === Networks.AVAX && <TimeMenu />}
                        {chainID === Networks.AVAX && <WrapButton />}
                        {!isVerySmallScreen && <NetworkMenu />}
                        <ConnectButton />
                    </div> */}
                </Toolbar>
            </AppBar>
        </div>
    );
}

export default Header;
