import React, { useState, useCallback } from "react";
import { useQueryParam, StringParam } from "use-query-params";
import "./stake.css";
import { Box, Grid, Typography, Zoom } from "@material-ui/core";
import stake from "../../assets/images/stake.png";
import earned from "../../assets/images/earned.png";
import active from "../../assets/images/active-stake.png";
import unclaimed from "../../assets/images/unclaimed.png";
// import eth from "../../assets/images/eth-icon.svg";
import bsc from '../../assets/networks/bsc-icon.svg'
import { BrowserRouter } from "react-router-dom";
import feature from "../../assets/images/feature-5.png";
import plan1 from "../../assets/images/plan-img01.png";
import plan2 from "../../assets/images/plan-img02.png";
import plan3 from "../../assets/images/plan-img03.png";
import plan4 from "../../assets/images/plan-img04.png";
import bot from "../../assets/images/bot.png";
import lock from "../../assets/images/icons8-lock.svg";
import ethscan from "../../assets/images/ethscan.png";
import certik from "../../assets/images/certik.png";
import Footer from "./component/Footer";
import Loading from "../../components/Loader";

import { usePathForNetwork, useWeb3Context } from "../../hooks";
import { useSelector, useDispatch } from "react-redux";
import { IPendingTxn, isPendingTxn, txnButtonText } from "../../store/slices/pending-txns-slice";
import { IReduxState } from "../../store/slices/state.interface";
import { messages } from "../../constants/messages";
import { changeStake, changeApproval } from "../../store/slices/stake-thunk";
import { useHistory } from "react-router-dom";
import { warning } from "../../store/slices/messages-slice";
import { trim } from "../../helpers";
import { id } from "ethers/lib/utils";
import Cookies from "universal-cookie";
import rot13 from "../../utils/encode";
import { BASE_URL } from "src/constants";
import { isAddress } from "../../utils";
import { success } from "../../store/slices/messages-slice";
import { GraphDetails } from "src/store/slices/app-slice";
import { BigNumber } from "ethers";
let days = [1209600000, 7776000000, 15552000000];
const StakeNew = () => {
    const { address: account } = useWeb3Context();
    const [swift, setswift] = useState("");
    const [double, setDouble] = useState("");
    const [tripe, setTriple] = useState("");
    const [unswift, setunswift] = useState("");
    const [undouble, setunDouble] = useState("");
    const [untripe, setunTriple] = useState("");    
    const [king, setKing] = useState("");
    const referlink = account ? `${BASE_URL}/#/stake?ref=${account}` : `${BASE_URL}/#/stake?ref=`;
    const [ref, setNum] = useQueryParam("ref", StringParam);
    const cookies = new Cookies();
    let refrralLinkAllow = false;

    if (ref) {
        if (isAddress(ref)) {
            cookies.set("ref", ref);
        }
    } else {
        cookies.set("ref", "0x0000000000000000000000000000000000000000");
    }

    const dispatch = useDispatch();
    const history = useHistory();
    const { provider, address, connect, chainID, checkWrongNetwork } = useWeb3Context();

    usePathForNetwork({ pathName: "stake", networkID: chainID, history });
    const isAppLoading = useSelector<IReduxState, boolean>(state => state.app.loading);
    const ethBalance = useSelector<IReduxState, string>(state => {
        return state.account.balances && state.account.balances.eth;
    });
    const stakeAllowance = useSelector<IReduxState, number>(state => {
        return state.account.staking && state.account.staking.eth;
    });
    const userStakeLength = useSelector<IReduxState, number>(state => {
        return state.account.staking && state.account.userInfo.stakeLength;
    });
    const userDepositAmount = useSelector<IReduxState, number>(state => {
        return state.account.userInfo.userDepositAmount;
    });
    const userWithdrawn = useSelector<IReduxState, number>(state => {
        return state.account.userInfo.userWithdrawn;
    });
    // const userActiveStaking = useSelector<IReduxState, number>(state => {
    //     return state.account.userInfo.userActiveStaking;
    // });
    const claimableEarning = useSelector<IReduxState, number>(state => {
        return state.account.userInfo.claimableEarning;
    });
    const userTotalReferralBonus = useSelector<IReduxState, number>(state => {
        return state.account.userInfo.userTotalReferralBonus;
    });
    const userPlanInfo = useSelector<IReduxState, number>(state => {
        return state.account.userInfo.userPlanInfo;
    });
    const userClaimAmount = useSelector<IReduxState, number[]>(state => {
        return state.account.userInfo.userClaimableAmounts;
    });
    const apyGraphArray = useSelector<IReduxState, GraphDetails[]>(state => {
        return state.app.stakingGraphDetails;
    });
    const eachStakingTVL =  useSelector<IReduxState, number[]>(state => {
        console.log('state.app.eachstakingTVL', state.app.eachstakingTVL);
        return state.app.eachstakingTVL;
    });
    const userCheckpoints = useSelector<IReduxState, BigNumber[]>(state => {
        return state.account.userInfo.userCheckPoints;
    });    
    const userActiveStaking = Number(userDepositAmount) - Number(userWithdrawn);

    if (userDepositAmount > 0) {
        refrralLinkAllow = true
    }

    const currency = "USD";

    const formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: currency,
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    });

    const convertDays = (date) => {
        // (Number(userCheckpoints[index]) * 1000 + days[index] - Date.now())
        let month = Math.floor(date / (86400000 * 30));        
        let days = Math.floor((date - month * (86400000 * 30)) / 86400000);
        let remain = date - month * (86400000 * 30);
        let hour = Math.floor((remain - days * 86400000) / 3600000);
        remain = (remain - days * 86400000) - hour * 3600000;
        let min = Math.floor(remain / 60000);
        let sec = Math.floor((remain % 60000) / 1000);
        return `${month?month:''} ${month ? 'months' : ''} ${days?days:0} days ${hour <= 10 ? '0': ''}${hour?hour:0}:${min <= 10 ? '0': ''}${min?min:0}:${sec <= 10 ? '0': ''}${sec?sec:0}`;    
    }

    const stakes = [
        {
            id: "0",
            icon: plan1,
            title: "BRONZE",
            dailyPercentage: "5%",
            planDetails: [
                { label: "APY", value: `${Number(apyGraphArray[0].rate[apyGraphArray[0].rate.length - 1]) ? (Number(apyGraphArray[0].rate[apyGraphArray[0].rate.length - 1]) / 1e6).toFixed(3) : 0} %` },
                { label: "Total Staked", value: `${Number(eachStakingTVL[0]).toFixed(1)} PACE` },                
                { label: "Locked period", value: "14 Days" },
                // { label: "Min Amount", value: "1 PACE" },
                { label: "Time Remaining", value: `${(Number(userPlanInfo[0]) / 10 ** 18 ? trim(Number(userPlanInfo[0]) / 10 ** 18, 4) : 0) != 0 ? convertDays((Number(userCheckpoints[0]) * 1000 + days[0] - Date.now())): '0 days 00:00:00'}`, active: true },
                { label: "You Staked", value: Number(userPlanInfo[0]) / 10 ** 18 ? Number(userPlanInfo[0]) / 10 ** 18 : 0, active: true },
                { label: "Claim Amount", value: `${Number(userClaimAmount[0])? Number(userClaimAmount[0]).toFixed(3): 0} PACE`, active: true },                  
            ],
            stateValue: swift,
            unstateValue: unswift,            
            stateSetter: setswift,
            stateunSetter: setunswift,
            button: "APPROVE",
            button1: "Claim",
            button2: 'Force Withdraw (15% fee)',
            button3: 'Withdraw' 
        },
        {
            id: "1",
            icon: plan2,
            title: "SILVER",
            dailyPercentage: "8%",
            planDetails: [
                { label: "APY", value: `${Number(apyGraphArray[1].rate[apyGraphArray[1].rate.length - 1]) ? (Number(apyGraphArray[1].rate[apyGraphArray[1].rate.length - 1]) / 1e6).toFixed(3) : 0} %` },
                { label: "Total Staked", value: `${Number(eachStakingTVL[1]).toFixed(1)} PACE` }, 
                { label: "Locked period", value: "3 months" },
                // { label: "Min Amount", value: "200 PACE" },               
                { label: "Time Remaining", value: `${(Number(userPlanInfo[1]) / 10 ** 18 ? trim(Number(userPlanInfo[1]) / 10 ** 18, 4) : 0) != 0 ? convertDays((Number(userCheckpoints[1]) * 1000 + days[1] - Date.now())): '0 days 00:00:00'}`, active: true },                      
                { label: "You Staked", value: Number(userPlanInfo[1]) / 10 ** 18 ? Number(userPlanInfo[1]) / 10 ** 18 : 0, active: true },
                { label: "Claim Amount", value: `${Number(userClaimAmount[1])?Number(userClaimAmount[1]).toFixed(3):0} PACE`, active: true },
            ],
            stateValue: double,
            unstateValue: undouble,               
            stateSetter: setDouble,
            stateunSetter: setunDouble,            
            button: "STAKE",
            button1: "Claim",
            button2: 'Force Withdraw (30% fee)',
            button3: 'Withdraw'           
        },
        {
            id: "2",
            icon: plan3,
            title: "GOLD",
            dailyPercentage: "12%",
            planDetails: [
                { label: "APY", value: `${Number(apyGraphArray[2].rate[apyGraphArray[2].rate.length - 1]) ? (Number(apyGraphArray[2].rate[apyGraphArray[2].rate.length - 1]) / 1e6).toFixed(3) : 0} %` },
                { label: "Total Staked", value: `${Number(eachStakingTVL[2]).toFixed(1)} PACE` }, 
                { label: "Locked period", value: "6 months" },
                // { label: "Min Amount", value: "500 PACE" },                              
                { label: "Time Remaining", value: `${(Number(userPlanInfo[2]) / 10 ** 18 ? trim(Number(userPlanInfo[2]) / 10 ** 18, 4) : 0) != 0 ? convertDays((Number(userCheckpoints[2]) * 1000 + days[2] - Date.now())): '0 days 00:00:00'}`, active: true },                
                { label: "You Staked", value: Number(userPlanInfo[2]) / 10 ** 18 ? Number(userPlanInfo[2]) / 10 ** 18 : 0, active: true },
                { label: "Claim Amount", value: `${Number(userClaimAmount[2])?Number(userClaimAmount[2]).toFixed(3):0} PACE`, active: true },
            ],
            stateValue: tripe,
            unstateValue: untripe,            
            stateSetter: setTriple,
            stateunSetter: setunTriple,            
            button: "RESTAKE",
            button1: "Claim",
            button2: 'Force Withdraw (45% fee)',
            button3: 'Withdraw'                                   
        },
        // {
        //     id: "3",
        //     icon: plan4,
        //     title: "GROK ME",
        //     dailyPercentage: "15%",
        //     planDetails: [
        //         { label: "APY", value: "5475%" },
        //         { label: "Locked period", value: "90 Days" },
        //         { label: "Min Amount", value: "1000 ETH" },
        //         { label: "You Staked", value: Number(userPlanInfo[3]) / 10 ** 18 ? Number(userPlanInfo[3]) / 10 ** 18 : 0, active: true },
        //     ],
        //     stateValue: king,
        //     stateSetter: setKing,
        //     button: "STAKE",
        //     button1: "COMPOUND",
        // },
    ];
    const totalRefBonus = useSelector<IReduxState, number>(state => {
        return state.app.totalRefBonus;
    });
    const pendingTransactions = useSelector<IReduxState, IPendingTxn[]>(state => {
        return state.pendingTransactions;
    });
    const setAmount = (percentage: number, item: { stateSetter: (value: string) => void }) => {
        const calculatedQuantity = (Number(ethBalance) * percentage) / 100;
        item.stateSetter(calculatedQuantity.toString());
    };
    const setUnAmount = (percentage: number, item: { stateunSetter: (value: string) => void }, value: number) => {
        const calculatedQuantity = (Number(value) * percentage) / 100;
        item.stateunSetter(calculatedQuantity.toString());
    };    
    const onSeekApproval = async (token: string) => {
        if (await checkWrongNetwork()) return;

        await dispatch(changeApproval({ address, token, provider, networkID: chainID }));
    };
    const hasAllowance = useCallback(
        token => {
            if (token === "eth") return stakeAllowance > 0;
            return 0;
        },
        [stakeAllowance],
    );

    console.log('debug->test', hasAllowance("eth"))
    const onChangeStake = async (action: string, planId: string, ref: string) => {
        if (await checkWrongNetwork()) return;
        let quantity;
        if(action != 'forcewithdraw' && action != 'withdraw') {
            switch (planId) {
                case "0":
                    quantity = swift;
                    break;
                case "1":
                    quantity = double;
                    break;
                case "2":
                    quantity = tripe;
                    break;
            }
        } else {
            switch (planId) {
                case "0":
                    quantity = unswift;
                    break;
                case "1":
                    quantity = undouble;
                    break;
                case "2":
                    quantity = untripe;
                    break;
            }
        }
        console.log('Action:', action, 'planId:', planId, quantity);
        if (action == "stake" && (quantity === "" || parseFloat(quantity) === 0)) {
            dispatch(warning({ text: action === "stake" ? messages.before_stake : messages.before_unstake }));
        } else if (action == "compound") {
            await dispatch(changeStake({ address, action, value: String(quantity), planId: planId, provider, networkID: chainID }));
            switch (planId) {
                case "0":
                    setswift("");
                    break;
                case "1":
                    setDouble("");
                    break;
                case "2":
                    setTriple("");
                    break;
            }
        } else if (action == 'claim') {
            await dispatch(changeStake({ address, action, value: String(quantity), planId: planId, provider, networkID: chainID }));
            switch (planId) {
                case "0":
                    setswift("");
                    break;
                case "1":
                    setDouble("");
                    break;
                case "2":
                    setTriple("");
                    break;
            }            
        } else {
            if (action == 'stake' && quantity >= 1) {
                await dispatch(changeStake({ address, action, value: String(quantity), planId: planId, provider, networkID: chainID }));
                switch (planId) {
                    case "0":
                        setswift("");
                        break;
                    case "1":
                        setDouble("");
                        break;
                    case "2":
                        setTriple("");
                        break;
                    case "3":
                        setKing("");
                        break;
                }
                location.reload();
                if (isAppLoading) return <Loading />;
            } else if((action == 'withdraw' || action == 'forcewithdraw') && quantity != 0) {
                await dispatch(changeStake({ address, action, value: String(quantity), planId: planId, provider, networkID: chainID }));
                switch (planId) {
                    case "0":
                        setunswift("");
                        break;
                    case "1":
                        setunDouble("");
                        break;
                    case "2":
                        setunTriple("");
                        break;
                    case "3":
                        setKing("");
                        break;
                }
                location.reload();
                if (isAppLoading) return <Loading />;
            }else {
                dispatch(warning({ text: action === "stake" ? messages.unsufficient_stake : messages.unsufficient_stake }));
            }
        }
    };
    const onChangeWithdraw = async (action: string) => {
        if (await checkWrongNetwork()) return;
        await dispatch(
            changeStake({
                address,
                action,
                provider,
                networkID: chainID,
                value: "",
                planId: "",
            }),
        );
    };
    const handleCopy = () => {
        const message = "successful copied"; // Replace with your actual warning message

        navigator.clipboard
            .writeText(referlink)
            .then(() => {
                dispatch(success({ text: messages.copy_ReferralLink }));
            })
            .catch(error => {
                dispatch({ type: "WARNING", payload: { text: message } });
            });
    };

    if (userDepositAmount >= 100) {
        refrralLinkAllow = true;
    }

    return (
        <BrowserRouter>
            <div className="stake-viewNew">
                <div style={{ zIndex: 99, paddingLeft: 10, paddingRight: 10 }}>
                    <Zoom in={true}>
                        <Grid>
                            <div className="top-stats">
                                <h3 className="dahsboard-sub-title">Your Staking Portfolio</h3>
                                <Grid container spacing={3}>
                                    {[
                                        // { img: stake, title: "My Total Staked", fig: userDepositAmount },
                                        { img: active, title: "My Total Staked", fig: Number(userPlanInfo[0]) / 10 ** 18 + Number(userPlanInfo[1]) / 10 ** 18 + Number(userPlanInfo[2]) / 10 ** 18 ? Number(userPlanInfo[0]) / 10 ** 18 + Number(userPlanInfo[1]) / 10 ** 18 + Number(userPlanInfo[2]) / 10 ** 18: 0 },
                                        // { img: earned, title: "Total Earned", fig: userActiveStaking },
                                        { img: unclaimed, title: "Claimable Earnings", fig: userClaimAmount[0] + userClaimAmount[1] + userClaimAmount[2]  ? userClaimAmount[0] + userClaimAmount[1] + userClaimAmount[2] : 0},
                                        // { img: plan1, title: 'BRONZE'},
                                        // { img: plan2, title: 'SILVER'},
                                        // { img: plan3, title: 'GOLD'},
                                    ].map((item, index) => (
                                        <Grid item lg={3} md={6} sm={6} xs={12} key={index}>
                                            <div className="card gradient">
                                                <div className="icon">
                                                    <img src={item.img} alt={item.title} />
                                                </div>
                                                <h3>{item.title}</h3>
                                                <div className="fig">
                                                    <span>
                                                        <img src={bsc} alt="PACE" /> PACE
                                                    </span>{" "}
                                                    <span style={{ fontWeight: 700 }}> {Number(item.fig).toFixed(2)} </span>
                                                </div>
                                            </div>
                                        </Grid>
                                    ))}
                                </Grid>
                            </div>
                            <Grid container spacing={3} className="admin-staking-plans">
                                <Grid item xs={12}>
                                    <div className="staking-title staking-head">
                                        {/* <h2>
                                            <span className="text-secondary">Stake</span> PACE, <span className="text-primary">Earn</span> PACE
                                        </h2> */}
                                        <div className="feature-box">
                                            <span className="text-secondary">Stake</span> PACE, <span className="text-primary">Earn</span> PACE
                                            <img src={feature} alt="Feature" />
                                        </div>
                                    </div>
                                </Grid>
                                {stakes.map((item, index) => (
                                    <Grid item xs={12} sm={6} md={6} lg={4} key={index}>
                                        <div className="plan-column staking-head">
                                            <div style={{ display: "flex" }}>
                                                <div className="stake-icon">
                                                    <img src={item.icon} alt={`Plan ${index + 1}`} />
                                                </div>
                                                <div className="stake-titles">
                                                    <h3>{item.title}</h3>
                                                    {/* <Typography variant="h4">
                                                        <b>Daily:</b> {item.dailyPercentage}
                                                    </Typography> */}
                                                </div>
                                            </div>
                                            <ul className="plan-details staking-head">
                                                {item.planDetails.map((detail, index1) => (
                                                    <li key={index1} className={detail.active ? "active" : ""}>
                                                        <div className="plan-inner">
                                                            <div className="plan-con">
                                                                <span>{detail.label}</span>
                                                                <Typography variant="h4">{detail.value}</Typography>
                                                            </div>
                                                        </div>
                                                    </li>
                                                ))}
                                            </ul>
                                            <div className="staking-field">
                                                <div className="staking-titles">
                                                    <div id="balance">Balance: {trim(Number(ethBalance), 4)}</div>
                                                    <div>Enter Staking Amount</div>
                                                </div>
                                                <div className="staking-form">
                                                    <div className="input-box">
                                                        <input
                                                            type="text"
                                                            placeholder="Enter Amount"
                                                            value={item.stateValue}
                                                            onChange={e => {
                                                                item.stateSetter(e.target.value);
                                                            }}
                                                        />
                                                        <div className="token-ticker">
                                                            <img src={bsc} alt="ETH" />
                                                            &nbsp; PACE
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="amount-percentage clearfix">
                                                    {[25, 50, 75, 100].map(percentage => (
                                                        <label key={percentage} onClick={() => setAmount(percentage, item)}>
                                                            <span>{percentage}%</span>
                                                        </label>
                                                    ))}
                                                </div>
                                                <div className="button-set full">
                                                    {/* <button className={`btn ${item.button == "approve" ? `btn-green` : `btn-primary`}`}>{item.button}</button> */}
                                                    {!hasAllowance("eth") ? (
                                                            <>
                                                                <button
                                                                    className="btn btn-primary"
                                                                    onClick={() => {
                                                                        if (isPendingTxn(pendingTransactions, "approve_staking")) return;
                                                                        onSeekApproval("eth");
                                                                    }}
                                                                >
                                                                    Approve
                                                                </button>
                                                            </>                                                                
                                                            // ) : (
                                                            //     <>
                                                            //         <button
                                                            //             className="btn btn-primary"
                                                            //             onClick={() => {
                                                            //                 if (isPendingTxn(pendingTransactions, "staking")) return;
                                                            //                 console.log("Stake");  
                                                            //                 onChangeStake("stake", item.id, ref);
                                                            //             }}
                                                            //         >
                                                            //             Stake
                                                            //         </button>
                                                            //     </>
                                                            // )}
                                                    ) : (
                                                        <>
                                                        <div className={`button-set ${item.button1 ? "half" : "full"}`}>
                                                            <button
                                                                className="btn btn-primary"
                                                                onClick={() => {
                                                                    if (isPendingTxn(pendingTransactions, "staking")) return;
                                                                    onChangeStake("stake", item.id, ref);
                                                                }}
                                                            >
                                                                Stake
                                                            </button>
                                                            {item.button1 && (
                                                                <button
                                                                    className="btn btn-secondary"
                                                                    onClick={() => {
                                                                        if (isPendingTxn(pendingTransactions, "staking")) return;
                                                                        onChangeStake("claim", item.id, ref);
                                                                    }}
                                                                >
                                                                    {item.button1}
                                                                </button>
                                                            )}
                                                        </div>
                                                        <br/>
                                                        <div className="staking-titles">
                                                                <div id="balance">Staked: {Number(item.planDetails[4].value).toFixed(2)}</div>
                                                                <div>Enter UnStaking Amount</div>
                                                            </div>
                                                            <div className="staking-form">
                                                                <div className="input-box">
                                                                    <input
                                                                        type="text"
                                                                        placeholder="Enter Amount"
                                                                        value={item.unstateValue}
                                                                        onChange={e => {
                                                                            item.stateunSetter(e.target.value);
                                                                        }}
                                                                    />
                                                                    <div className="token-ticker">
                                                                        <img src={bsc} alt="ETH" />
                                                                        &nbsp; PACE
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="amount-percentage clearfix">
                                                                {[25, 50, 75, 100].map(percentage => (
                                                                    <label key={percentage} onClick={() => setUnAmount(percentage, item, Number(item.planDetails[4].value))}>
                                                                        <span>{percentage}%</span>
                                                                    </label>
                                                                ))}
                                                            </div>
                                                        <div className={`button-set ${Number(userCheckpoints[index]) + days[index] > Date.now() ? "half" : "full"}`}>
                                                            {item.button2 && (
                                                                <button
                                                                    className="btn btn-primary"
                                                                    onClick={() => {
                                                                        if (isPendingTxn(pendingTransactions, "staking")) return;
                                                                        onChangeStake("forcewithdraw", item.id, ref);
                                                                    }}
                                                                >
                                                                    {item.button2}
                                                                </button>
                                                            )}
                                                            {item.button3 && (
                                                                Number(userCheckpoints[index]) + days[index] > Date.now() ?
                                                                <button
                                                                    className="btn btn-secondary"
                                                                    onClick={() => {
                                                                        if (isPendingTxn(pendingTransactions, "staking")) return;
                                                                        onChangeStake("withdraw", item.id, ref);
                                                                    }}
                                                                >
                                                                    {item.button3}
                                                                </button>:
                                                                <></>
                                                            )}
                                                        </div>
                                                        </>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </Grid>
                                ))}
                            </Grid>

                            {/* <div className="referal-box">
                                <div className="referal-title text-center">
                                    <h2>
                                        Maximize Earnings with our <span className="text-secondary">Seven-Tier</span> Referral Program - Potential Rewards up to &nbsp;
                                        <span className="text-primary">30%</span>
                                    </h2>
                                </div>
                                <Grid xs={12}>
                                    <Box className="boxpadding">
                                        <div className="ref-container card">
                                            <h2 className={refrralLinkAllow ? "" : "blur"}>
                                                Stake <span className="bottle-green">Together</span> and <span className="green">Earn</span> Rewards
                                            </h2>
                                            <div className={`link-box staking-head ${refrralLinkAllow ? "" : "blur"}`}>
                                                <div className="link-title">
                                                    <i className="fa-solid fa-link"></i> Referal Link
                                                </div>
                                                <div className="r-link">{referlink}</div>
                                                <button className="btn btn-secondary medium" onClick={handleCopy}>
                                                    Copy
                                                </button>
                                            </div>
                                            {!refrralLinkAllow && (
                                                <div className="locked-overlay text-center">
                                                    <img src={lock} className="lockIcon" />

                                                    <h3> the Referral System by Staking $PACE in Any Plan</h3>
                                                </div>
                                            )}
                                        </div>
                                    </Box>
                                </Grid>
                            </div> */}
                        </Grid>
                    </Zoom>
                </div>
            </div>
            <Footer />
        </BrowserRouter>
    );
};

export default StakeNew;
