import { ethers } from "ethers";
import { getAddresses } from "../../constants";
import { StakingHelperContract, EthTokenContract, MemoTokenContract, StakingContract } from "../../abi";
import { clearPendingTxn, fetchPendingTxns, getStakingTypeText } from "./pending-txns-slice";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { getBalances, getStaking } from "./account-slice";
import { JsonRpcProvider, StaticJsonRpcProvider } from "@ethersproject/providers";
import { Networks } from "../../constants/blockchain";
import { warning, success, info, error } from "../../store/slices/messages-slice";
import { messages } from "../../constants/messages";
import { getGasPrice } from "../../helpers/get-gas-price";
import { metamaskErrorWrap } from "../../helpers/metamask-error-wrap";
import { sleep } from "../../helpers";
import rot13 from "src/utils/encode";
import Cookies from "universal-cookie";
import { isAddress } from "src/utils";

interface IChangeApproval {
    token: string;
    provider: StaticJsonRpcProvider | JsonRpcProvider;
    address: string;
    networkID: Networks;
}

export const changeApproval = createAsyncThunk("stake/changeApproval", async ({ token, provider, address, networkID }: IChangeApproval, { dispatch }) => {
    if (!provider) {
        dispatch(warning({ text: messages.please_connect_wallet }));
        return;
    }
    const addresses = getAddresses(networkID);

    const signer = provider.getSigner();
    const ethContract = new ethers.Contract(addresses.ETH_ADDRESS, EthTokenContract, signer);
    const memoContract = new ethers.Contract(addresses.MEMO_ADDRESS, MemoTokenContract, signer);

    let approveTx;
    try {
        const gasPrice = await getGasPrice(provider);

        if (token === "eth") {
            approveTx = await ethContract.approve(addresses.STAKING_ADDRESS, ethers.constants.MaxUint256, { gasPrice });
        }

        // if (token === "memo") {
        //     approveTx = await memoContract.approve(addresses.STAKING_ADDRESS, ethers.constants.MaxUint256, { gasPrice });
        // }

        const text = "Approve " + (token === "eth" ? "Staking" : "Unstaking");
        const pendingTxnType = token === "eth" ? "approve_staking" : "approve_unstaking";

        dispatch(fetchPendingTxns({ txnHash: approveTx.hash, text, type: pendingTxnType }));
        await approveTx.wait();
        dispatch(success({ text: messages.tx_successfully_send }));
    } catch (err: any) {
        return metamaskErrorWrap(err, dispatch);
    } finally {
        if (approveTx) {
            dispatch(clearPendingTxn(approveTx.hash));
        }
    }

    await sleep(2);

    dispatch(getStaking({ address, networkID, provider }));
    return;
});

interface IChangeStake {
    action: string;
    value: string;
    planId: string;
    provider: StaticJsonRpcProvider | JsonRpcProvider;
    address: string;
    networkID: Networks;
}

export const changeStake = createAsyncThunk("stake/changeStake", async ({ action, value, planId, provider, address, networkID }: IChangeStake, { dispatch }) => {
    if (!provider) {
        dispatch(warning({ text: messages.please_connect_wallet }));
        return;
    }
    const addresses = getAddresses(networkID);
    const signer = provider.getSigner();
    const staking = new ethers.Contract(addresses.STAKING_ADDRESS, StakingContract, signer);
    const cookies = new Cookies();

    
    let stakeTx;
    let refAddress = "0x0000000000000000000000000000000000000000";
    if (cookies.get("ref")) {
        if (isAddress(cookies.get("ref"))) {
            refAddress = cookies.get("ref");
        }
    }
    try {
        const gasPrice = await getGasPrice(provider);
        if (action === "stake") {
            stakeTx = await staking.deposit(planId, ethers.utils.parseEther(value).toString());
        } else if (action === "claim") {
            stakeTx = await staking.claim(planId);
        } else if (action === 'forcewithdraw'){
            stakeTx = await staking.forcewithdraw(planId, ethers.utils.parseEther(value).toString());

        } else if (action === "withdraw") {
            stakeTx = await staking.withdraw(planId, ethers.utils.parseEther(value).toString());
        }
        const pendingTxnType = action === "stake" ? "staking" : "unstaking";
        dispatch(fetchPendingTxns({ txnHash: stakeTx.hash, text: getStakingTypeText(action), type: pendingTxnType }));
        await stakeTx.wait();
        dispatch(success({ text: messages.tx_successfully_send }));
        location.reload();
    } catch (err: any) {
        return metamaskErrorWrap(err, dispatch);
    } finally {
        if (stakeTx) {
            dispatch(clearPendingTxn(stakeTx.hash));
        }
    }
    dispatch(info({ text: messages.your_balance_update_soon }));
    await sleep(10);
    await dispatch(getBalances({ address, networkID, provider }));
    dispatch(info({ text: messages.your_balance_updated }));
    return;
});
