export const messages = {
    please_connect: "Please connect your wallet to the Ethereum network to use EthMining.",
    please_connect_wallet: "Please connect your wallet.",
    try_mint_more: (value: string) => `You're trying to mint more than the maximum payout available! The maximum mint payout is ${value} TIME.`,
    before_minting: "Before minting, enter a value.",
    existing_mint:
        "You have an existing mint. Minting will reset your vesting period and forfeit any pending claimable rewards. We recommend claiming rewards first or using a fresh wallet. Do you still wish to proceed?",
    before_stake: "Before staking, enter a value.",
    unsufficient_stake: "Please check Min Amount for stake and enter valid value.",
    before_unstake: "Before un staking, enter a value.",
    tx_successfully_send: "Your transaction was successfully sent",
    your_balance_updated: "Your balance was successfully updated",
    nothing_to_claim: "You have nothing to claim",
    something_wrong: "Something went wrong",
    copy_ReferralLink: "successful copied",
    switch_to_avalanche: "Switch to the Ethereum network?",
    slippage_too_small: "Slippage too small",
    slippage_too_big: "Slippage too big",
    your_balance_update_soon: "Your balance will update soon",
    before_wrap: "Before wrapping, enter a value.",
    before_unwrap: "Before un wrapping, enter a value.",
    before_bridge: "Before bridge, enter a value.",
    rpc_connection_lost: "Rpc connection lost",
    min_bridge_amount: "Minimum Crosschain Amount 0.00063 wMEMO",
    before_redemption: "Before redemption, enter a value.",
};
