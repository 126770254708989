import React from "react";

import ethscan from "../../../assets/images/ethscan.png";
import certik from "../../../assets/images/certik.png";
const Footer = () => {
    return (
        <>
            {" "}
            <div className="admin-footer staking-head">
                <p className="copyright-admin">Copyright © 2024 PACE Mining Limited. All rights reserved</p>
                {/* <div className="footer-logos">
                    <a href="#" target="_blnak">
                        <img src={certik} />
                    </a>
                    <a href="#" style={{ marginLeft: "30px" }} target="_blnak">
                        <img src={ethscan} />
                    </a>
                </div> */}
            </div>
        </>
    );
};

export default Footer;
