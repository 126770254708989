import React, { useEffect, useState } from "react";
import { useWeb3Context } from "../../hooks";
import "./prom.css";
import banner from "../../assets/images/banner.gif";
import Footer from "../Stake/component/Footer";
import { useSelector, useDispatch } from "react-redux";
import { success } from "src/store/slices/messages-slice";
import { messages } from "src/constants/messages";
import { BASE_URL } from "src/constants";
const Promotion = () => {
    const { address: account } = useWeb3Context();
    const referlink = account ? `${BASE_URL}/#/stake?ref=${account}` : `${BASE_URL}/#/stake?ref=`;
    const [activeTab, setActiveTab] = useState("tab1");
    const dispatch = useDispatch();

    const handleTabClick = (tabId: any) => {
        setActiveTab(tabId);
    };
    const tabData = [
        { id: "tab1", size: "250x250" },
        { id: "tab2", size: "125x125" },
        { id: "tab3", size: "200x200" },
        { id: "tab4", size: "300x300" },
    ];
    const tabData1 = [{ id: "tab1", size: "250x250" }];
    const tabData2 = [{ id: "tab2", size: "125x125" }];
    const tabData3 = [{ id: "tab3", size: "200x200" }];
    const tabData4 = [{ id: "tab4", size: "300x300" }];
    const [rLinkContent, setRLinkContent] = useState("");
    useEffect(() => {
        const rLinkElement = document.querySelector(".r-link");
        if (rLinkElement) {
            const content = rLinkElement.textContent;
            setRLinkContent(content);
        }
    }, []);
    const handleCopy = () => {
        const message = "successful copied"; // Replace with your actual warning message

        navigator.clipboard
            .writeText(rLinkContent)
            .then(() => {
                dispatch(success({ text: messages.copy_ReferralLink }));
            })
            .catch(error => {
                dispatch({ type: "WARNING", payload: { text: message } });
            });
    };

    return (
        <div>
            <div className="main-wrapper">
                <div className="tabs">
                    <ul className="tab-links clearfix">
                        {tabData.map(tab => (
                            <li key={tab.id} className={activeTab === tab.id ? "active" : ""} onClick={() => handleTabClick(tab.id)}>
                                <a>{tab.size}</a>
                            </li>
                        ))}
                    </ul>
                    <div className="tab-content">
                        {activeTab === "tab1"
                            ? tabData1.map(tab => (
                                  <div key={tab.id} id={tab.id} className={`tab ${activeTab === tab.id ? "active" : ""} banner-wrapper`}>
                                      <div className="banner-header">
                                          <span>{tab.size}</span>
                                      </div>
                                      <div className="banner-body">
                                          <img src={banner} alt="Banner" />
                                      </div>
                                      <div className="banner-footer">
                                          <div className="link-box d-flex align-items-center">
                                              <div className="link-title">
                                                  <i className="fa-solid fa-link"></i> HTML Code
                                              </div>
                                              <code className="r-link">
                                                  &lt;a href="{referlink}"&gt; &lt;img src="banners/banner-250x250.gif"&gt; &lt;/a&gt;
                                              </code>
                                              <button className="btn btn-secondary small" onClick={handleCopy}>
                                                  Copy
                                              </button>
                                          </div>
                                      </div>
                                  </div>
                              ))
                            : activeTab === "tab2"
                            ? tabData2.map(tab => (
                                  <div key={tab.id} id={tab.id} className={`tab ${activeTab === tab.id ? "active" : ""} banner-wrapper`}>
                                      <div className="banner-header">
                                          <span>{tab.size}</span>
                                      </div>
                                      <div className="banner-body">
                                          <img src={banner} alt="Banner" />
                                      </div>
                                      <div className="banner-footer">
                                          <div className="link-box d-flex align-items-center">
                                              <div className="link-title">
                                                  <i className="fa-solid fa-link"></i> HTML Code
                                              </div>
                                              <code className="r-link">
                                                  &lt;a href="{referlink}"&gt; &lt;img src="banners/banner-{tab.size}.gif"&gt; &lt;/a&gt;
                                              </code>
                                              <button className="btn btn-secondary small">Copy</button>
                                          </div>
                                      </div>
                                  </div>
                              ))
                            : activeTab === "tab3"
                            ? tabData3.map(tab => (
                                  <div key={tab.id} id={tab.id} className={`tab ${activeTab === tab.id ? "active" : ""} banner-wrapper`}>
                                      <div className="banner-header">
                                          <span>{tab.size}</span>
                                      </div>
                                      <div className="banner-body">
                                          <img src={banner} alt="Banner" />
                                      </div>
                                      <div className="banner-footer">
                                          <div className="link-box d-flex align-items-center">
                                              <div className="link-title">
                                                  <i className="fa-solid fa-link"></i> HTML Code
                                              </div>
                                              <code className="r-link">
                                                  &lt;a href="{referlink}"&gt; &lt;img src="banners/banner-{tab.size}.gif"&gt; &lt;/a&gt;
                                              </code>
                                              <button className="btn btn-secondary small">Copy</button>
                                          </div>
                                      </div>
                                  </div>
                              ))
                            : activeTab === "tab4"
                            ? tabData4.map(tab => (
                                  <div key={tab.id} id={tab.id} className={`tab ${activeTab === tab.id ? "active" : ""} banner-wrapper`}>
                                      <div className="banner-header">
                                          <span>{tab.size}</span>
                                      </div>
                                      <div className="banner-body">
                                          <img src={banner} alt="Banner" />
                                      </div>
                                      <div className="banner-footer">
                                          <div className="link-box d-flex align-items-center">
                                              <div className="link-title">
                                                  <i className="fa-solid fa-link"></i> HTML Code
                                              </div>
                                              <code className="r-link">
                                                  &lt;a href="{referlink}"&gt; &lt;img src="banners/banner-{tab.size}.gif"&gt; &lt;/a&gt;
                                              </code>
                                              <button className="btn btn-secondary small">Copy</button>
                                          </div>
                                      </div>
                                  </div>
                              ))
                            : tabData1.map(tab => (
                                  <div key={tab.id} id={tab.id} className={`tab ${activeTab === tab.id ? "active" : ""} banner-wrapper`}>
                                      <div className="banner-header">
                                          <span>{tab.size}</span>
                                      </div>
                                      <div className="banner-body">
                                          <img src={banner} alt="Banner" />
                                      </div>
                                      <div className="banner-footer">
                                          <div className="link-box d-flex align-items-center">
                                              <div className="link-title">
                                                  <i className="fa-solid fa-link"></i> HTML Code
                                              </div>
                                              <code className="r-link">
                                                  &lt;a href="{referlink}"&gt; &lt;img src="banners/banner-{tab.size}.gif"&gt; &lt;/a&gt;
                                              </code>
                                              <button className="btn btn-secondary small" onClick={handleCopy}>
                                                  Copy
                                              </button>
                                          </div>
                                      </div>
                                  </div>
                              ))}
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default Promotion;
