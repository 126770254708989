import React, { useEffect, useState } from "react";
import eth from "../../assets/images/eth-icon.svg";
import { Grid, Zoom } from "@material-ui/core";
import "./ref.css";
import Footer from "../Stake/component/Footer";
import refer from "../../assets/images/active-refer.png";
import turn from "../../assets/images/turnover.png";
import static0 from "../../assets/images/statis-ico04.png";
import static1 from "../../assets/images/statis-ico04.png";
import { useDispatch, useSelector } from "react-redux";
import { IReduxState } from "src/store/slices/state.interface";
import { trim } from "src/helpers";
import CanvasJSReact from '@canvasjs/react-charts';
import { usePathForNetwork, useWeb3Context } from "src/hooks";
import { useHistory } from "react-router-dom";
import { randomBytes } from "crypto";
import { GraphDetails } from "src/store/slices/app-slice";
var CanvasJS = CanvasJSReact.CanvasJS;
var CanvasJSChart = CanvasJSReact.CanvasJSChart;
const options = {
    animationEnabled: true,
    exportEnabled: true,
    theme: "dark2", // "light1", "dark1", "dark2"
    title:{
        text: "APY of pools"
    },
    axisY: {
        title: "APY Rate",
        suffix: '%'
    },
    axisX: {
        title: "Time",
        interval: 2
    },
    toolTip: {
        shared: true
    },
    data: []
};
const Referal = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { provider, address, connect, chainID, checkWrongNetwork } = useWeb3Context();

    // usePathForNetwork({ pathName: "graph", networkID: chainID, history });
    const apyGraphArray = useSelector<IReduxState, GraphDetails[]>(state => {
        return state.app.stakingGraphDetails;
    });
    const [option, setOptions] = useState(options);

    useEffect(() => {
        let opt = {
            animationEnabled: true,
            exportEnabled: true,
            theme: "dark2", // "light1", "dark1", "dark2"
            title:{
                text: "APY of pools"
            },
            axisY: {
                title: "APY Rate",
                suffix: '%'
            },
            axisX: {
                title: "Time",
                interval: 2
            },
            toolTip: {
                shared: true
            },
            data: []
        };
        let timestampmin = apyGraphArray[0].timestamp[0];
        timestampmin = timestampmin > apyGraphArray[1].timestamp[0] ? apyGraphArray[1].timestamp[0] : timestampmin;
        timestampmin = timestampmin > apyGraphArray[2].timestamp[0] ? apyGraphArray[2].timestamp[0] : timestampmin;
        for(let i = 0; i < 3; i++) {
            const {rate, timestamp} = apyGraphArray[i];
            const d = {
                type: "line",
                showInLegend: true,
                name: `APY Rate of ${i+1}${i?i==1?'nd':'rd':'st'} pool`,
                toolTipContent: `${i+1}${i?i==1?'nd':'rd':'st'} pool {x}: {y}%`,
                xValueFormatString: "MMM DD YYYY hh:mm:ss",
                yValueFormatString: "#,##0.##",
                dataPoints: []
            }
            d.dataPoints.push({x: new Date((Number(timestampmin)) * 1000), y: Number(rate[0]) / 1e6});            
            for(let j = 0; j < rate.length; j++) {
                console.log("Time", Number(timestamp[j]), "Rate", Number(rate[j]));
                d.dataPoints.push({x: new Date((Number(timestamp[j])) * 1000), y: Number(rate[j]) / 1e6});
            }
            d.dataPoints.push({x: Date.now(), y: Number(rate[rate.length - 1]) / 1e6});                  
            opt.data.push(d);
        }
        setOptions(opt);
    //   return () => clearInterval(interval);
    }, [])

    return (
        <div>
            <div className="ref-viewNew">
                <div style={{ zIndex: 99, paddingLeft: 10, paddingRight: 10 }}>
                    <Zoom in={true}>
                        <Grid>
                            <div className="top-stats">
                                {/* <Grid container spacing={3}>
                                    {[
                                        { img: refer, title: "Your Referals", fig: userTotalReferrals.toString() },
                                        { img: turn, title: "Total Turnover", fig: trim(Number(userTotalTurnover.toString()), 2) },
                                        { img: static0, title: "Earned Referral Reward", fig: trim(Number(userReferralWithdrawn.toString()), 2) },
                                        { img: static1, title: "Claimable Referral Reward", fig: trim(Number(userReferralBonus.toString()), 2) },
                                    ].map((item, index) => (
                                        <Grid item lg={3} md={6} sm={6} xs={12} key={index}>
                                            <div className="card gradient">
                                                <div className="icon">
                                                    <img src={item.img} alt={item.title} />
                                                </div>
                                                <h3 style={{fontSize: '18px'}}>
                                                    {item.title}
                                                </h3>
                                                <div className="fig">
                                                    {item.img == refer ? (
                                                        <span>
                                                            <img src={eth} alt="ETH" />
                                                        </span>
                                                    ) : (
                                                        <span>
                                                            <img src={eth} alt="ETH" /> ETH
                                                        </span>
                                                    )}{" "}
                                                    <span style={{ fontWeight: 700 }}> {item.fig} </span>
                                                    {/* {item.title === "Claimable referral Reward" && (
                                                        <div className="claim-container">
                                                            <button className="btn btn-primary small claim">Claim</button>
                                                        </div>
                                                    )} */}
                                                {/* </div>
                                            </div>
                                        </Grid>
                                    ))}
                                </Grid>  */}
                            </div>
                            <div className="refera-title staking-head justify-content-between">
                                <h3 className="dahsboard-sub-title">APY Graph</h3>
                                {/* <div className="your-level">
                                    <i className="fa-solid fa-users"></i> &nbsp;&nbsp;Your Current Level: <span>{referralLevel}</span>
                                </div> */}
                            </div>
                            {/* <ol className="steps">
                                {Levels.map(level => (
                                    <li key={level.level} className={level.status === "active" ? "current" : ""}>
                                        <a >{level.LevelName}</a>
                                    </li>
                                ))}
                            </ol> */}
                            {/* <ol className="steps">
                                <li className={(referralLevel - 1) === 0 ? "current" : ""}>
                                    <a>Level-1</a>
                                </li>
                                <li className={(referralLevel - 1) === 1 ? "current" : ""}>
                                    <a>Level-2</a>
                                </li>
                                 <li className={(referralLevel - 1) === 2 ? "current" : ""}>
                                    <a>Level-3</a>
                                </li>
                                <li className={(referralLevel - 1) === 3 ? "current" : ""}>
                                    <a>Level-4</a>
                                </li>
                                <li className={(referralLevel - 1) === 4 ? "current" : ""}>
                                    <a>Level-5</a>
                                </li>
                                <li className={(referralLevel - 1) === 5 ? "current" : ""}>
                                    <a>Level-6</a>
                                </li>
                                <li className={(referralLevel - 1) === 6 ? "current" : ""}>
                                    <a>Level-7</a>
                                </li>
                            </ol> */}

                            {/* <ul className="level-list clearfix">
                                {Levels.map((level, index) => (
                                    <li key={index}>
                                        <div className={`level-box ${level.status}`}>
                                            <h3>
                                                {level.percentage}
                                                <span>%</span>
                                                <b>Reward</b>
                                            </h3>
                                            <div className="turnover">
                                                <b>Turnover:</b> {level.turnover} ETH
                                            </div>
                                            <div className="level">Level {level.level}</div>
                                            {/* <div className="referal-no">
                                                <i className="fa-solid fa-user-group"></i> {level.level ? parseInt(userReferralCount[level.level - 1]) : ""}
                                            </div>
                                            <div className="ref-status">
                                                {level.status === "finished" && (
                                                    <div className="finished-badge">
                                                        <span>
                                                            <i className="fa-regular fa-circle-check"></i>
                                                        </span>{" "}
                                                        Achieved
                                                    </div>
                                                )}
                                                {level.status === "active" && <button className="btn btn-primary small">Active</button>}
                                                {level.status === "locked" && (
                                                    <div className="locked-badge">
                                                        <span>
                                                            <i className="fa-solid fa-lock"></i>
                                                        </span>{" "}
                                                        Locked
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </li>
                                ))}
                            </ul> */}

                            <CanvasJSChart options = {option}/>
                        </Grid>
                    </Zoom>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default Referal;
